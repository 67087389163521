import React, { Component } from "react";
import moment from "moment";

class CryptoNews extends Component {
  state = {
    thisSelectedNews: "XRP",
    newsListLanguage: "EN",
    newsMarkup: "",
    newsApiUrl:
      "https://min-api.cryptocompare.com/data/v2/news/?lang=EN&categories="
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.getCryptoNews();
  }
  componentDidUpdate(nextState, nextProps) {
    if (nextState.selected !== nextProps.thisSelectedNews) {
      this.setState(
        {
          thisSelectedNews: nextState.selected
        },
        () => this.getCryptoNews()
      );
      return true;
    } else {
      return false;
    }
  }

  getCryptoNews() {
    const getData = () => {
      const url = `${this.state.newsApiUrl}${this.state.thisSelectedNews}`;
      // console.log("CRYPTO NEWS", this.state.thisSelectedNews);
      fetch(url)
        .then(r => r.json())
        .then(cryptoNews => {
          // console.log(cryptoNews);
          const sortedData = [];
          const objectLenght = Object.keys(cryptoNews.Data).length;
          let count = 0;

          for (let key in cryptoNews.Data) {
            sortedData.push({
              date: moment(cryptoNews.Data[key].published_on * 1000).format(
                "MMMM DD YYYY hh:mm a"
              ),
              title: cryptoNews.Data[key].title,
              body: cryptoNews.Data[key].body,
              url: cryptoNews.Data[key].url,
              source: cryptoNews.Data[key].source,
              count: count,
              key: sortedData.key
            });

            count++;
            if (parseInt(key) === objectLenght - 1) {
              this.setState(
                {
                  newsData: sortedData
                },
                () => this.getNewsItem()
              );
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    getData();
  }

  getNewsItem() {
    const newsData = this.state.newsData;
    let item = [
      <h3 key="cryptoNewsH" className="news-headline">
        Crypto<b>News</b>
      </h3>
    ];

    // Replace special character fron api string
    const decodeHtmlCharCodes = str =>
      str.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
        String.fromCharCode(charCode)
      );

    // Outer loop to create parent
    for (let i = 0; i < 10; i++) {
      item.push(
        <div key={`cryptoNew${i}`} className="row row-custom">
          <a
            className="col-sm col-sm-custom"
            href={newsData[i].url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>
              <span>{decodeHtmlCharCodes(newsData[i].title)}</span>
            </h3>
            <p>{decodeHtmlCharCodes(newsData[i].body)}</p>
            <div className="news-source">
              <b>{newsData[i].source}</b> - {newsData[i].date}
            </div>
          </a>
        </div>
      );
      if (i === 9) {
        this.setState({
          newsMarkup: item
        });
      }
    }
  }

  render() {
    return (
      <div id="cryptoNews" className="container container-custom">
        {this.state.newsMarkup}
      </div>
    );
  }
}

export default CryptoNews;
