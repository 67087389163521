import React, { Component } from "react";
// import { WithContext as ReactTags } from "react-tag-input";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownCurrencys from "./dropdownCurrencys";
import PropTypes from "prop-types";
import "font-awesome/css/font-awesome.min.css";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";

import arrayMove from "array-move";

const cc = require("cryptocompare");

class CalcExchange extends Component {
  state = {
    targetCurrency: [
      this.props.currencyASelected,
      "XRP",
      "BTC",
      this.props.currencyASelected
    ],
    currencyInvestLog: 0,
    currencyASelected: "",
    prices: [],
    updatePrice: true,
    tagError: "",
    returnInPercentDirection: "caret-up",
    items: ["XRP", "HOT", "BTC"]
  };

  componentDidMount() {
    this.handleTagCalculationClick();
  }

  handleDelete(e) {
    let newItems = this.state.items;

    newItems = newItems.filter(item => {
      return item !== e;
    });

    this.setState(
      {
        items: newItems
      },
      () => this.handleTagCalculationClick()
    );
  }

  getCurrentPrice() {
    // Passing a single pair of currencies:
    // Basic Usage: cc.price("XRP", "EUR")
    //console.log("this.state.targetCurrency", this.state.targetCurrency);

    cc.priceMulti(this.state.targetCurrency, this.state.targetCurrency)
      .then(prices => {
        // console.log(prices);
        // console.log("### PRICE-UPDATE", this.state.updatePrice);
        this.setState(
          {
            prices: prices,
            updatePrice: false
          },
          () => this.calculate(prices)
        );

        // -> { BTC: { USD: 1114.63, EUR: 1055.82 },
        //      ETH: { USD: 12.74, EUR: 12.06 } }
      })
      .catch(console.error);
  }

  // Get Values from Child Component to this (Parent Component)
  static childContextTypes = {
    handleAddTag: PropTypes.func
  };

  componentDidUpdate(nextState, nextProps) {
    if (nextState.currencyASelected !== nextProps.currencyASelected) {
      this.setState(
        {
          currencyASelected: nextState.currencyASelected,
          updatePrice: true
        },
        () => this.handleTagCalculationClick()
      );
      return true;
    }

    if (
      nextState.currencyInvest !== nextProps.currencyInvest &&
      !isNaN(nextState.currencyInvest)
    ) {
      this.setState(
        {
          currencyInvest: nextState.currencyInvest
        },
        () => this.handleTagCalculationClick()
      );
      return true;
    }
  }

  getChildContext() {
    return {
      handleAddTag: (id, parameter_from_child) =>
        this.handleAddTag(id, parameter_from_child)
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex)
      }),
      () => this.handleTagCalculationClick()
    );
  };

  // Add new Tags to sortable List
  handleAddTag(id, parameter_from_child) {
    this.setState(
      {
        items: [...this.state.items, parameter_from_child],
        updatePrice: true
      },
      () => this.handleTagCalculationClick()
    );
  }

  handleTagCalculationClick() {
    let tagArray = [];
    tagArray.push(this.props.currencyASelected);
    for (var i = 0, len = this.state.items.length; i < len; i++) {
      tagArray.push(this.state.items[i]);

      if (i === this.state.items.length - 1) {
        // console.log("### DEBUG", this.props.currencyASelected);
        tagArray.push(this.props.currencyASelected);

        this.setState(
          {
            targetCurrency: tagArray
          },
          () => {
            // console.log("### Update Price", this.state.updatePrice);
            if (this.state.updatePrice === true) {
              // console.log("### UPDATE THE PRICE");
              this.getCurrentPrice();
            } else {
              // console.log("### UPDATE NOT THE PRICE");
              this.calculate(this.state.prices);
            }
          }
        );
      }
    }
  }

  calculate(prices) {
    let targetCurrency = this.state.targetCurrency;
    let calcPrice = this.props.currencyInvest;
    let currencyUpMarkup = [];
    for (let i = 0; i < targetCurrency.length; i++) {
      if (i !== targetCurrency.length - 1) {
        calcPrice =
          calcPrice / prices[targetCurrency[i + 1]][targetCurrency[i]];

        currencyUpMarkup.push([
          <div key={`currencyUp${i}`} className="row row-custom">
            <div className="col-sm col-sm-custom col-sm-custom--as-headline">
              <div className="desc--headline">
                currency<b>pair</b>
              </div>{" "}
              <div className="desc--value">
                {targetCurrency[i]} / {targetCurrency[i + 1]}
              </div>
            </div>
            <div className="col-sm col-sm-custom">
              <div className="desc--headline">
                current<b>price</b>
              </div>
              <div className="desc--value">
                {prices[targetCurrency[i + 1]][targetCurrency[i]]}{" "}
                {targetCurrency[i]}
              </div>
            </div>
            <div className="col-sm col-sm-custom">
              <div className="desc--headline">
                currency<b>target</b>
              </div>
              <div className="desc--value">
                {calcPrice.toFixed(6)} {targetCurrency[i + 1]}
              </div>
            </div>
            <div className="row row-custom row-custom-exchange-icon">
              <FontAwesomeIcon
                icon="exchange-alt"
                size="2x"
                color="rgba(232, 251, 251, 0.75)"
              />
            </div>
          </div>
        ]);
      } else {
        this.setState(
          {
            currencyUpMarkup: currencyUpMarkup,
            lastOfList: calcPrice.toFixed(6)
          },
          () => {
            let returnInPercent = (
              (100 / this.props.currencyInvest) *
              (this.state.lastOfList - this.props.currencyInvest)
            ).toFixed(3);

            let returnInPercentDirection = "caret-up";
            if (returnInPercent < 0) {
              returnInPercentDirection = "caret-down";
            }

            this.setState({
              returnInPercent: returnInPercent,
              returnInPercentDirection: returnInPercentDirection
            });
          }
        );
      }
    }
  }

  render() {
    // const { tags, suggestions } = this.state;
    const DragHandle = sortableHandle(() => (
      <span className="fa fa-exchange" />
    ));

    let SortableItem = sortableElement(({ value }) => (
      <li>
        <DragHandle />
        {value}
        <span
          className="fa fa-times-circle"
          onClick={() => {
            this.handleDelete(value);
          }}
        />
      </li>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="tag-list">{children}</ul>;
    });
    const { items } = this.state;
    return (
      <div id="calcExchange">
        <h3>
          calc<b>exchange</b>
        </h3>
        <div className="row row-custom">
          <div className="col-sm col-sm-custom">
            <div className="desc">
              <span>
                exchange<b>list</b>
              </span>
              <div className="desc-aditional">
                <DropdownCurrencys
                  items={this.props.currencyA}
                  componentIdent="calcExchange"
                  ref={component => (this._child = component)}
                />
              </div>
            </div>

            <div className="currencyUp-tags" data-tip data-for="currencyUp">
              <SortableContainer
                onSortEnd={this.onSortEnd}
                useDragHandle
                axis="x"
                helperClass="sortable-tag-helper"
              >
                {items.map((value, index) => (
                  <SortableItem
                    key={`item-${index}`}
                    index={index}
                    value={value}
                  />
                ))}
              </SortableContainer>
            </div>
            <div className="currencyUp-list">
              <div className="row row-custom">
                <div className="col-sm col-sm-custom col-sm-custom-invest">
                  start<b>invest</b>
                  <div className="desc--value">
                    {this.props.currencyInvest} {this.props.currencyASelected}
                  </div>
                </div>
              </div>
              <ReactTooltip
                id="currencyUp"
                className="application-tooltip"
                place="top"
                type="dark"
                effect="solid"
              >
                <div className="application-tooltip--content">
                  <h3>
                    EXCHANGE<b>LIST</b>
                  </h3>
                  <p>
                    Add a currency which should be integrated into the change
                    process. Change the order of the change process by dragging
                    and dropping the currencies. The CURRENCY<b>INVEST</b> is
                    always the start and the goal of the change process. All
                    exchanges are without fees!
                  </p>
                </div>
              </ReactTooltip>
              {this.state.currencyUpMarkup}
              <div className="row row-custom">
                <div className="col-sm col-sm-custom col-sm-custom-invest">
                  <span className="desc--value-line-adjust">
                    final<b>out</b>
                  </span>
                  <div className="desc--value">
                    <FontAwesomeIcon
                      icon={this.state.returnInPercentDirection}
                      size="2x"
                      color="#ffffff"
                    />
                    <span className="desc--value-vertical-adjust">
                      {this.state.returnInPercent} % {"  "}
                      {this.state.lastOfList} {this.props.currencyASelected}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CalcExchange;
