import React, { Component } from "react";
import DropdownCurrencys from "./dropdownCurrencys";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga";
import { digitCalculation } from "../helpers/digitCalculation";
import ReactTooltip from "react-tooltip";
import CryptoNews from "./cryptoNews";
import CalcExchange from "./calcExchange";
import CalcCurrencies from "./calcCurrencies";
import Social from "./social";
import Highcharts from 'highcharts/highstock';             
import HighchartsReact from 'highcharts-react-official';   
const cc = require("cryptocompare");
cc.setApiKey(
  "b8400f3a5ca78beb6c88a9e40cabc6d0e9728030f763675e65fb522a5ece282a"
);

const HighchartsMore = require("highcharts/highcharts-more");
HighchartsMore(Highcharts);

const ScrollListener = require("react-scroll-listener");
let previousPosition = 0;

ReactGA.initialize("UA-133366283-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class MillionCalculation extends Component {
  state = {
    currencyInvest: 1000,
    currencyInvestClass: "number",
    currencyTarget: 1000,
    currencyTargetClass: "number",
    availableCurrencys: [
      "ADA",
      "BCH",
      "BNB",
      "BSV",
      "BTC",
      "BTT",
      "DASH",
      "ETH",
      "EOS",
      "ETC",
      "LINK",
      "LTC",
      "MIOTA",
      "NEO",
      "USDT",
      "TRX",
      "HOT",
      "XMR",
      "XVG",
      "XLM",
      "XRP"
    ],
    currencyA: { id: "currencyA", selected: "EUR" },
    currencyB: { id: "currencyB", selected: "XRP" },
    currencyASelected: "EUR",
    currencyBSelected: "XRP",
    chartData: [],
    currentPriceData: [],
    averagePriceData: [],
    chartTimeDuration: "day",
    changePct24HourDirection: "caret-up",
    yourProfitDirection: "caret-up",
    loader: "loader",
    trendRangeColorA: "rgba(50, 100, 255, 0.1)",
    trendRangeColorB: "rgba(50, 100, 255,0)"
  };

  // Constructor
  constructor(props) {
    super(props);

    this.getValueFromCurrencyInvest = this.getValueFromInput.bind(
      this,
      "currencyInvest"
    );
    this.getValueFromCurrencyTarget = this.getValueFromInput.bind(
      this,
      "currencyTarget"
    );
    this.setValueFromCurrencyTarget = this.reloadCurrencyTarget.bind(
      this,
      "reload"
    );
    this.getCoinList();
    this.getCurrentPrice();
    this.unixTimeConvert(1412743274);
  }

  componentDidMount() {
    var scrollListener = new ScrollListener();
    scrollListener.addScrollHandler(
      "calculatorOutput",
      this.scrollStartHandler
    );

    (function() {
      setTimeout(() => {
        var element = document.getElementById("loader");
        element.classList.add("loader-hidden");
      }, 1000);
    })();
  }

  // Get Values from Child Component to this (Parent Component)

  static childContextTypes = {
    parentMethod: PropTypes.func
  };

  getChildContext() {
    return {
      parentMethod: (id, parameter_from_child) =>
        this.parentMethod(id, parameter_from_child)
    };
  }

  parentMethod(id, parameter_from_child) {
    const that = this;
    if (id === "currencyA") {
      this.setState({
        currencyASelected: parameter_from_child
      });
    } else if (id === "currencyB") {
      this.setState({
        currencyBSelected: parameter_from_child
      });
    } else {
      return false;
    }
    let currentTime = Math.floor(Date.now() / 1000);
    setTimeout(function() {
      sessionStorage.setItem("selectedA", that.state.currencyASelected);
      sessionStorage.setItem("selectedB", that.state.currencyBSelected);
      sessionStorage.setItem("histoMinuteExpire", currentTime - 1000);
      sessionStorage.setItem("histoHourExpire", currentTime - 1000);
      sessionStorage.setItem("histoDayExpire", currentTime - 1000);
      that.getCurrentPrice();
    }, 75);
  }

  // Get Current Price from - npm cryptocompage
  getCurrentPrice() {
    // Passing a single pair of currencies:
    // Basic Usage: cc.price("XRP", "EUR")
    this.getHistoSocial();
    cc.priceFull(this.state.currencyBSelected, this.state.currencyASelected)
      .then(prices => {
        /* console.log(
          "PRICES",
          prices[this.state.currencyBSelected][this.state.currencyASelected]
        );*/
        // console.log("### getCurrentPrice ###", prices);
        // Define price values
        const fullPriceSelector =
          prices[this.state.currencyBSelected][this.state.currencyASelected];
        // console.log(fullPriceSelector);

        const currentPrice = parseFloat(fullPriceSelector.PRICE.toFixed(6));
        const change24Hour = fullPriceSelector.CHANGE24HOUR.toFixed(6);
        const changePct24Hour = fullPriceSelector.CHANGEPCT24HOUR.toFixed(4);
        const changeDay = fullPriceSelector.CHANGEDAY.toFixed(6);
        const changePctDay = fullPriceSelector.CHANGEPCTDAY.toFixed(4);
        const priceLowDay = fullPriceSelector.LOWDAY.toFixed(6);
        const priceHightDay = fullPriceSelector.HIGHDAY.toFixed(6);
        const suppliy = fullPriceSelector.SUPPLY;
        const marketCap = fullPriceSelector.MKTCAP.toFixed(2);
        let currencyTarget = "";

        if (!isNaN(this.state.currencyInvest)) {
          currencyTarget = (
            this.state.currencyInvest / fullPriceSelector.PRICE
          ).toFixed(6);
        } else {
          currencyTarget = 0;
        }

        // Update State with price values

        this.setState(
          {
            currentPrice: currentPrice,
            change24Hour: change24Hour,
            changePct24Hour: changePct24Hour,
            changeDay: changeDay,
            changePctDay: changePctDay,
            priceLowDay: priceLowDay,
            priceHightDay: priceHightDay,
            suppliy: suppliy,
            marketCap: marketCap,
            currencyTarget: currencyTarget
          },
          this.calculate
        );
      })
      .catch(console.error);
  }

  // Get coinList from - npm cryptocompage
  getCoinList() {
    const that = this
    let coinListFromStorage = JSON.parse(sessionStorage.getItem("coinList"));
    // console.log(coinListFromStorage);
    // Use Session Storage to reduce API-calls zu cryptocompare
    if (coinListFromStorage) {
      console.log("getCoinList() - LS");
      setTimeout(() => this.coinListFactory(coinListFromStorage), 50);
    } else {
      console.log("getCoinList() - CC");
      cc.coinList()
        .then(coinList => {
          console.log("coinList", coinList, that.state.availableCurrencys, coinList.Data);
          Object.keys(coinList.Data).forEach(function(key, index, arr) {
            if (that.state.availableCurrencys.indexOf(key) !== -1) {
              console.log("key", key, index)
            }
          });
          // sessionStorage.setItem("coinList", JSON.stringify(coinList.Data));
          setTimeout(() => this.coinListFactory(coinList.Data), 50);
        })
        .catch(console.error);
    }
  }

  coinListFactory(coinList) {
    const that = this;
    let coinListItemsA = {
      id: "currencyA",
      selected: "EUR",
      EUR: { FullName: "EURO (EUR)" },
      USD: { FullName: "US Dollar (USD)" }
    };

    let coinListItemsB = {
      id: "currencyB",
      selected: "XRP",
      EUR: { FullName: "EURO (EUR)" },
      USD: { FullName: "US Dollar (USD)" }
    };

    Object.keys(coinList).forEach(function(key, index, arr) {
      // console.log("coinListFactory", coinList[key].FullName);
      if (that.state.availableCurrencys.indexOf(key) !== -1) {
        // LIST A
        coinListItemsA[key] = {
          FullName: coinList[key].FullName,
          SortOrder: coinList[key].SortOrder,
          ImageUrl: coinList[key].ImageUrl
        };

        // LIST B
        coinListItemsB[key] = {
          FullName: coinList[key].FullName,
          SortOrder: coinList[key].SortOrder,
          ImageUrl: coinList[key].ImageUrl
        };
        // console.log("coinListItems", key, coinListItemsA);
      }
      if (!arr[index + 1]) {
        that.setState({
          currencyA: coinListItemsA,
          currencyB: coinListItemsB
        });
      }
    });
  }

  chartDataFactory(prices) {
    //console.log("### chartDataFactory");
    const that = this;
    const pricesLength = Object.keys(prices).length;
    let chartDataArray = [];
    let currentPriceArray = [];
    let averagePriceArray = [];
    let trendPriceUpArray = [];
    let trendPriceDownArray = [];
    let trendPriceAverageArray = [];
    let trendUpPrice = 999999999999999999999;
    let trendUpIndex = 0;
    let trendUpTime = 0;
    let trendDownPrice = 0;
    let trendDownIndex = 0;
    let trendDownTime = 0;

    Object.keys(prices).forEach(function(key, index, arr) {
      // Chart Data
      chartDataArray.push([
        that.unixTimeConvert(prices[key].time),
        prices[key].close
      ]);

      // Current Price Data Line
      currentPriceArray.push(
        [that.unixTimeConvert(prices[key].time), that.state.currentPrice],
        [
          that.unixTimeConvert(prices[pricesLength - 1].time),
          that.state.currentPrice
        ]
      );

      // Average Price Data Line
      if (that.state.currentPrice !== that.state.average) {
        averagePriceArray.push(
          [that.unixTimeConvert(prices[key].time), that.state.average],
          [
            that.unixTimeConvert(prices[pricesLength - 1].time),
            that.state.average
          ]
        );
      }

      // Trend Price Line
      // Trend Line UP
      if (prices[key].close < trendUpPrice) {
        trendUpIndex = index;
        trendUpTime = that.unixTimeConvert(prices[key].time);
        trendUpPrice = prices[key].close;
      }

      // Trend Line Down
      if (prices[key].close > trendDownPrice) {
        trendDownIndex = index;
        trendDownTime = that.unixTimeConvert(prices[key].time);
        trendDownPrice = prices[key].close;
      }

      // Push everything and set states
      if (!arr[index + 1]) {
        trendPriceDownArray.push([trendDownTime, trendDownPrice]);
        trendPriceUpArray.push([trendUpTime, trendUpPrice]);

        let calcTrendLineUp = trendUpPrice;
        let calcTrendLineDown = trendDownPrice;

        // Calculate Steps in line from lowest down and highest up to current price
        // Is required for geting range of trend.
        Object.keys(prices).forEach(function(key, index, arr) {
          if (index > trendUpIndex) {
            calcTrendLineUp =
              calcTrendLineUp +
              (prices[pricesLength - 1].close - trendUpPrice) /
                (pricesLength - 1 - trendUpIndex);

            trendPriceUpArray.push([
              that.unixTimeConvert(prices[key].time),
              calcTrendLineUp
            ]);
          }

          if (index > trendDownIndex) {
            calcTrendLineDown =
              calcTrendLineDown +
              (prices[pricesLength - 1].close - trendDownPrice) /
                (pricesLength - 1 - trendDownIndex);

            trendPriceDownArray.push([
              that.unixTimeConvert(prices[key].time),
              calcTrendLineDown
            ]);
          }

          if (!arr[index + 1]) {
            let trendPriceDownIdent = 0;
            let trendPriceUpIdent = 0;

            if (trendUpTime < trendDownTime) {
              Object.keys(prices).forEach(function(key, index, arr) {
                trendPriceDownIdent = trendPriceDownArray[0][0];

                if (!arr[index + 1]) {
                  trendPriceUpArray.forEach(function(element) {
                    if (element.includes(trendPriceDownIdent) === true) {
                      const priceRange = parseFloat(
                        trendPriceDownArray[0][1] - element[1]
                      );
                      const priceCurrentToDown =
                        trendPriceDownArray[0][1] -
                        prices[pricesLength - 1].close;
                      const priceCurrentToUp = parseFloat(
                        prices[pricesLength - 1].close - element[1]
                      );
                      const priceDownPCT =
                        (100 / priceRange) * priceCurrentToDown;
                      const priceUpPCT = (100 / priceRange) * priceCurrentToUp;

                      that.setState({
                        trendUp: priceUpPCT.toFixed(2) + "%",
                        trendDown: priceDownPCT.toFixed(2) + "%"
                      });
                    }
                  });
                }
              });
            } else {
              Object.keys(prices).forEach(function(key, index, arr) {
                trendPriceUpIdent = trendPriceUpArray[0][0];

                if (!arr[index + 1]) {
                  trendPriceDownArray.forEach(function(element) {
                    if (element.includes(trendPriceUpIdent) === true) {
                      const priceRange = parseFloat(
                        trendPriceUpArray[0][1] - element[1]
                      );
                      const priceCurrentToUp =
                        trendPriceUpArray[0][1] -
                        prices[pricesLength - 1].close;
                      const priceCurrentToDown = parseFloat(
                        prices[pricesLength - 1].close - element[1]
                      );
                      const priceUpPCT = (100 / priceRange) * priceCurrentToUp;
                      const priceDownPCT =
                        (100 / priceRange) * priceCurrentToDown;

                      that.setState({
                        trendDown: priceDownPCT.toFixed(2) + "%",
                        trendUp: priceUpPCT.toFixed(2) + "%"
                      });
                    }
                  });
                }
              });
            }
          }
        });

        if (that.state.currentPrice !== 0) {
          trendPriceUpArray.push([
            that.unixTimeConvert(prices[key].time),
            that.state.currentPrice
          ]);

          trendPriceDownArray.push([
            that.unixTimeConvert(prices[key].time),
            that.state.currentPrice
          ]);

          trendPriceDownArray.forEach(function(entryDown) {
            trendPriceUpArray.forEach(function(entryUp) {
              if (entryDown[0] === entryUp[0]) {
                trendPriceAverageArray.push([
                  entryDown[0],
                  entryDown[1],
                  entryUp[1]
                ]);
              }
            });
          });
        }

        that.setState({
          chartData: chartDataArray,
          currentPriceData: currentPriceArray,
          averagePriceData: averagePriceArray,
          trendPriceUpData: trendPriceUpArray,
          trendPriceDownData: trendPriceDownArray,
          trendPriceAverageData: trendPriceAverageArray
        });
      }
    });
  }

  chartTimeDuration(timeSelector) {
    // console.log("### chartTimeDuration");
    this.setState({
      chartTimeDuration: timeSelector
    });
    setTimeout(() => this.getPriceHistorical(), 25);
  }

  getHistoSocial() {
    const coinList = JSON.parse(sessionStorage.getItem("coinList"));
    cc.histoSocial("day", {
      coinId: coinList === null ? "5031" : coinList[this.state.currencyBSelected].Id,
      limit: 1095
    }).then(socialStats => {
      this.setState({ social: socialStats });
    });
  }

  // Get historical ChartData from cryptocompare
  getPriceHistorical() {
    // console.log("### getPriceHistorical");
    let currentTime = Math.floor(Date.now() / 1000);
    let histoASelected = sessionStorage.getItem("selectedA");
    let histoBSelected = sessionStorage.getItem("selectedB");
    let chartDataCalculated = "";

    if (
      histoASelected !== this.state.currencyASelected ||
      histoBSelected !== this.state.currencyBSelected
    ) {
      sessionStorage.setItem("selectedA", this.state.currencyASelected);
      sessionStorage.setItem("selectedB", this.state.currencyBSelected);
      sessionStorage.setItem("histoMinuteExpire", currentTime - 1000);
      sessionStorage.setItem("histoHourExpire", currentTime - 1000);
      sessionStorage.setItem("histoDayExpire", currentTime - 1000);

      this.reloadCurrencyTarget();
    }

    // BY Minute
    // console.log("getPriceHistorical", this.state.chartTimeDuration);
    if (this.state.chartTimeDuration === "minute") {
      // console.log("chartTimeDuration", this.state.chartTimeDuration);
      const histoMinute = JSON.parse(sessionStorage.getItem("histoMinute"));
      const histoMinuteExpire = JSON.parse(
        sessionStorage.getItem("histoHourExpire")
      );

      if (histoMinute && histoMinuteExpire > currentTime) {
        //console.log("HISTO Minute - LS");
        chartDataCalculated = histoMinute;
        this.chartDataFactory(chartDataCalculated);
      } else {
        //console.log("HISTO Minute - CC");
        cc.histoMinute(
          this.state.currencyBSelected,
          this.state.currencyASelected,
          { limit: 1440 }
        )
          .then(prices => {
            sessionStorage.setItem("histoMinute", JSON.stringify(prices));
            sessionStorage.setItem("histoMinuteExpire", currentTime + 60);
            chartDataCalculated = prices;
            this.chartDataFactory(chartDataCalculated);
          })
          .catch(console.error);
      }
    }

    // By Hour - Update SessionStorage every 120s
    if (this.state.chartTimeDuration === "hour") {
      // console.log("chartTimeDuration", this.state.chartTimeDuration);
      const histoHour = JSON.parse(sessionStorage.getItem("histoHour"));
      const histoHourExpire = JSON.parse(
        sessionStorage.getItem("histoHourExpire")
      );

      if (histoHour && histoHourExpire > currentTime) {
        //console.log("HISTO Hour - LS");
        chartDataCalculated = histoHour;
        this.chartDataFactory(chartDataCalculated);
      } else {
        //console.log("HISTO Hour - CC");
        cc.histoHour(
          this.state.currencyBSelected,
          this.state.currencyASelected,
          { limit: 168 }
        )
          .then(prices => {
            sessionStorage.setItem("histoHour", JSON.stringify(prices));
            sessionStorage.setItem("histoHourExpire", currentTime + 60);
            chartDataCalculated = prices;
            this.chartDataFactory(chartDataCalculated);
          })
          .catch(console.error);
      }
    }

    // By Day
    if (this.state.chartTimeDuration === "day") {
      // console.log("chartTimeDuration", this.state.chartTimeDuration);
      const histoDay = JSON.parse(sessionStorage.getItem("histoDay"));
      const histoDayExpire = JSON.parse(sessionStorage.getItem("histoDayExpire"));

      if (histoDay && histoDayExpire > currentTime) {
        //console.log("HISTO Day - LS");
        chartDataCalculated = histoDay;
        this.chartDataFactory(chartDataCalculated);
      } else {
        // console.log("HISTO Day - CC");
        cc.histoDay(
          this.state.currencyBSelected,
          this.state.currencyASelected,
          { limit: 365 }
        )
          .then(prices => {
            sessionStorage.setItem("histoDay", JSON.stringify(prices));
            sessionStorage.setItem("histoDayExpire", currentTime + 60);
            chartDataCalculated = prices;

            this.chartDataFactory(chartDataCalculated);
          })
          .catch(console.error);
      }
    }
  }

  // Get Values from input fields currencyA and currencyB
  getValueFromInput(refName, e) {
    // console.log("### getValueFromInput", refName, e.type, e.keyCode);

    if (e.type === "blur" || e.keyCode === 13) {
      let inputValue = e.target.value;
      let currencyTargetCalc = parseFloat(inputValue);
      let currencyTargetClass = "number";

      if (parseFloat(inputValue) > 0 && inputValue !== "") {
        if (isNaN(e.target.value)) {
          inputValue = 0;
        }
        currencyTargetCalc.toFixed(6);

        // Reduce Digits for currencyTarget if digits grow up to limit count digits.
        if (refName === "currencyInvest") {
          if (digitCalculation(currencyTargetCalc) < 4) {
            currencyTargetCalc = (inputValue / this.state.currentPrice).toFixed(
              6
            );
          } else if (digitCalculation(currencyTargetCalc) < 6) {
            currencyTargetCalc = (inputValue / this.state.currentPrice).toFixed(
              4
            );
          } else if (digitCalculation(currencyTargetCalc) > 8) {
            currencyTargetCalc = (inputValue / this.state.currentPrice).toFixed(
              2
            );
            currencyTargetClass = "number smaler-font";
          } else {
            currencyTargetCalc = (inputValue / this.state.currentPrice).toFixed(
              2
            );
          }
        }

        this.setState(
          {
            [refName]: parseFloat(inputValue),
            currencyTarget: parseFloat(currencyTargetCalc),
            currencyTargetClass: currencyTargetClass
          },
          () => {
            if (refName === "reload") {
              this.reloadCurrencyTarget();
            } else {
              this.calculate();
            }
          }
        );
      }
    }
  }

  reloadCurrencyTarget(refName, e) {
    let currentTime = Math.floor(Date.now() / 1000);
    sessionStorage.setItem("histoMinuteExpire", currentTime - 1000);
    sessionStorage.setItem("histoHourExpire", currentTime - 1000);
    sessionStorage.setItem("histoDayExpire", currentTime - 1000);
    this.setState(
      {
        averagePriceData: []
      },
      () => this.getCurrentPrice()
    );
  }

  // Calculate Data
  calculate() {
    // console.log("### calculate");
    let currencyInvest = this.state.currencyInvest;
    let currencyTarget = this.state.currencyTarget;

    if (isNaN(currencyInvest)) {
      currencyInvest = 0;
      this.setState({ currencyInvest: 0 });
    } else if (isNaN(currencyTarget)) {
      currencyTarget = 0;
      this.setState({ currencyTarget: 0 });
    } else {
      // PRICE AVERAGE - needed for currancyB change
      let average = (currencyInvest / currencyTarget).toFixed(6);

      if (isNaN(average)) {
        average = 0;
      }

      let aggregateValue = (this.state.currentPrice * currencyTarget).toFixed(
        6
      );

      // RETURN IN CURRENCY
      let returnInCurrency = (
        this.state.currentPrice * currencyTarget -
        currencyInvest
      ).toFixed(2);

      // RETURN IN PERCENT
      let returnInPercent = (
        (100 / currencyInvest) * (this.state.currentPrice * currencyTarget) -
        100
      ).toFixed(3);

      if (isNaN(returnInPercent)) {
        returnInPercent = 0;
      }

      let averageChartColor = "transparent";
      if (returnInPercent === "-0.000" || returnInPercent === "0.00") {
        averageChartColor = "rgba(1, 36, 35, 0.65)";
      } else if (returnInPercent < 0) {
        averageChartColor = "#df0000";
      } else {
        averageChartColor = "#148c00";
      }

      let yourProfitDirection = "caret-up";
      if (returnInPercent < 0) {
        yourProfitDirection = "caret-down";
      }

      let changePct24HourDirection = "caret-up";
      if (this.state.changePct24Hour < 0) {
        changePct24HourDirection = "caret-down";
      }

      let changePctDayDirection = "caret-up";
      if (this.state.changePctDay < 0) {
        changePctDayDirection = "caret-down";
      }
      // Bei diesem Preis ist man bei aktuellem Investment und Preis, Millionär
      let oneMillionPrice = (1000000 / currencyTarget).toFixed(4);

      // Set State
      this.setState(
        {
          average: parseFloat(average),
          aggregateValue: parseFloat(aggregateValue),
          returnInCurrency: parseFloat(returnInCurrency),
          returnInPercent: parseFloat(returnInPercent),
          oneMillionPrice: parseFloat(oneMillionPrice),
          averageChartColor: averageChartColor,
          changePct24HourDirection: changePct24HourDirection,
          changePctDayDirection: changePctDayDirection,
          yourProfitDirection: yourProfitDirection
        },
        () => this.getPriceHistorical()
      );
    }
  }

  // ####### Helpers #######
  // scrollListener to hide inputNavbar
  scrollStartHandler = function(event) {
    const calculatorInput = document.getElementById("calculatorInput");

    if (window.pageYOffset > 120 && window.pageYOffset > previousPosition) {
      calculatorInput.classList.add("inputToggleClose");
      calculatorInput.classList.remove("inputToggleOpen");
    } else {
      calculatorInput.classList.add("inputToggleOpen");
      calculatorInput.classList.remove("inputToggleClose");
      previousPosition = window.pageYOffset + 50;
    }

    previousPosition = window.pageYOffset;
  };

  // unix Time Converter
  unixTimeConvert(unixTime) {
    const dt = new Date(unixTime * 1000);
    const year = dt.getFullYear();
    const month = dt.getMonth();
    const day = dt.getDate();
    const hr = dt.getHours();
    const m = "0" + dt.getMinutes();
    const s = "0" + dt.getSeconds();
    return Date.UTC(year, month, day, hr, m, s);
  }
  // ####### END Helpers #######

  // Render Application
  render() {
    // console.log("props", this.state);
    const chartConfig = {
      chart: {
        styledMode: false,
        zoomType: "x",
        animation: {
          duration: 1000
        }
      },
      credits: {
        enabled: false
      },

      rangeSelector: {
        enabled: false,
        selected: 1
      },

      legend: {
        enabled: true,
        symbolWidth: 8
      },
      plotOptions: {
        series: {
          showInLegend: true,
          fillOpacity: 0.5
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, "rgba(232, 251, 251, 0.75)"],
              [1, "rgba(251, 251, 251, 1)"]
            ]
          },
          marker: {
            radius: 5
          },
          lineWidth: 1,
          lineColor: "#012423",
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      navigator: {
        handles: {
          backgroundColor: "#e8fcfb",
          borderColor: "#012423"
        },
        maskFill: "rgba(232, 251, 251, 0.75)",
        maskInside: true
      },

      title: {
        text: this.state.currencyASelected + "/" + this.state.currencyBSelected
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          // don't display the dummy year
          day: "%e. %b"
        },
        title: {
          text: "Date"
        }
      },
      yAxis: {
        gridLineColor: "rgba(1, 36, 35, 0.25)",
        title: {
          text: "Price"
        }
      },
      series: [
        {
          type: "area",
          name: `selected Price (${this.state.currencyASelected})`,
          data: this.state.chartData,
          color: "rgba(1, 36, 35, 0.65)",
          tooltip: {
            valueDecimals: 4
          }
        },
        {
          type: "arearange",
          data: this.state.trendPriceAverageData,
          showInLegend: false,
          enableMouseTracking: false,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, this.state.trendRangeColorA],
              [1, this.state.trendRangeColorB]
            ]
          }
        },
        {
          name: `Your profit (${this.state.currencyASelected}): ${
            this.state.returnInCurrency
          }${this.state.currencyASelected} ${this.state.returnInPercent}%`,
          data: this.state.averagePriceData,
          color: this.state.averageChartColor,
          tooltip: {
            valueDecimals: 4
          }
        },
        {
          name: `Current Price (${this.state.currencyASelected})`,
          data: this.state.currentPriceData,
          color: "#ffe400",
          marker: {
            symbol: "circle"
          },
          tooltip: {
            valueDecimals: 4
          }
        },
        {
          name: "Price Trend Top",
          data: this.state.trendPriceDownData,
          color: "rgba(255, 0, 0, 0.5)",
          marker: {
            symbol: "triangle-down"
          },
          tooltip: {
            valueDecimals: 4
          }
        },
        {
          name: "Price Trend Bottom",
          data: this.state.trendPriceUpData,
          color: "rgba(0, 255, 0, 0.5)",
          marker: {
            symbol: "triangle"
          },
          tooltip: {
            valueDecimals: 4
          }
        }
      ]
    };
    return (
      <div>
        <div className="row-custom-header">
          <div id="calculatorInput" className="row row-custom inputToggleOpen">
            <div className="col-sm">
              <div className="input-group">
                <div className="inputLabel">
                  Currency<b>invest</b>
                </div>
                <div className="btn-group">
                  <DropdownCurrencys
                    items={this.state.currencyA}
                    ref={component => (this._child = component)}
                  />
                  <div className="dropdown-menu">...</div>
                </div>
                <input
                  id="currencyInvestInput"
                  className="form-control"
                  type="number"
                  step="any"
                  name="currencyInvest"
                  onKeyUp={this.getValueFromCurrencyInvest}
                  onBlur={this.getValueFromCurrencyInvest}
                  ref="currencyInvest"
                  placeholder={this.state.currencyInvest}
                />
              </div>
            </div>
            <div className="col-sm">
              <div className="input-group">
                <div className="inputLabel">
                  Currency<b>target</b>
                </div>
                <div className="input-group-prepend">
                  <div className="btn-group">
                    <DropdownCurrencys
                      items={this.state.currencyB}
                      ref={component => (this._child = component)}
                    />
                    <div className="dropdown-menu" />
                  </div>
                </div>
                <input
                  id="currencyTarget"
                  className="form-control"
                  type="number"
                  step="any"
                  name="currencyTarget"
                  onKeyUp={this.getValueFromCurrencyTarget}
                  onBlur={this.getValueFromCurrencyTarget}
                  ref="currencyTarget"
                  placeholder={this.state.currencyTarget}
                />
                <div
                  onClick={this.setValueFromCurrencyTarget}
                  ref="reload"
                  className="glyphicon-refresh"
                >
                  <FontAwesomeIcon icon="redo-alt" size="2x" color="#012423" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="calculatorOutput" className="container container-custom">
          <div className="content-block">
            <h3 className="no-space">
              calc<b>average</b>
            </h3>
            <div className="row row-custom">
              <div className="col-sm col-sm-custom">
                <div className="desc">
                  current<b>Price</b> ({this.state.currencyASelected}/
                  {this.state.currencyBSelected}){" "}
                  <div className="desc-aditional">
                    <FontAwesomeIcon
                      icon={this.state.changePct24HourDirection}
                      size="2x"
                      color="#012423"
                    />

                    <span
                      className="direction-icon"
                      data-tip
                      data-for="changePct24HourDirection"
                    >
                      {this.state.changePct24Hour}%
                    </span>
                    <ReactTooltip
                      id="changePct24HourDirection"
                      className="application-tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      <span>Price change in the last 24 hours.</span>
                    </ReactTooltip>
                  </div>
                </div>

                <div className="number">
                  <span data-tip data-for="currentPrice">
                    {this.state.currentPrice} {this.state.currencyASelected}
                  </span>
                  <ReactTooltip
                    id="currentPrice"
                    className="application-tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <span>Current Price of selected currency pair.</span>
                  </ReactTooltip>
                </div>
              </div>
              <div className="col-sm col-sm-custom">
                <div className="desc">
                  Currency<b>invest</b>
                </div>
                <div className={this.state.currencyInvestClass}>
                  {this.state.currencyInvest} {this.state.currencyASelected}
                </div>
              </div>

              <div className="col-sm col-sm-custom">
                <div className="desc">
                  Currency<b>target</b>
                </div>
                <div className={this.state.currencyTargetClass}>
                  {this.state.currencyTarget} {this.state.currencyBSelected}
                </div>
              </div>
            </div>
            <div className="row row-custom">
              <div className="col-sm col-sm-custom">
                <div className="desc">
                  Your price<b>average</b> ({this.state.currencyASelected}/
                  {this.state.currencyBSelected})
                </div>
                <div className="number">
                  {this.state.average} {this.state.currencyASelected}
                </div>
              </div>
              <div className="col-sm col-sm-custom">
                <div className="desc">
                  your<b>profit</b>
                  <div className="desc-aditional">
                    <FontAwesomeIcon
                      icon={this.state.yourProfitDirection}
                      size="2x"
                      color="#012423"
                    />

                    <span
                      className="direction-icon"
                      data-tip
                      data-for="returnInPercent"
                    >
                      {this.state.returnInPercent} %
                    </span>
                    <ReactTooltip
                      id="returnInPercent"
                      className="application-tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      <span>
                        This is your profit, the result of <br />
                        your entry shows a profit of{" "}
                        {this.state.returnInPercent} %
                      </span>
                    </ReactTooltip>
                  </div>
                </div>
                <div className="number">
                  <span data-tip data-for="yourProfit">
                    {this.state.returnInCurrency} {this.state.currencyASelected}
                  </span>
                  <ReactTooltip
                    id="yourProfit"
                    className="application-tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <span>
                      This is your profit, the result of <br />
                      your entry shows a profit of {
                        this.state.returnInCurrency
                      }{" "}
                      {this.state.currencyASelected}
                    </span>
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <h3>
              pair<b>chart</b>
            </h3>
            <div className="row row-custom">
              <div className="col-sm col-sm-custom col-sm-custom-chart">
                <Button
                  className="chartTimeButton"
                  onClick={() => {
                    this.chartTimeDuration("day");
                  }}
                >
                  <span data-tip data-for="dayChart">
                    day
                  </span>
                  <ReactTooltip
                    id="dayChart"
                    className="application-tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <span>Chart range: last 365 days</span>
                  </ReactTooltip>
                </Button>
                <Button
                  className="chartTimeButton"
                  onClick={() => {
                    this.chartTimeDuration("hour");
                  }}
                >
                  <span data-tip data-for="hourChart">
                    hour
                  </span>
                  <ReactTooltip
                    id="hourChart"
                    className="application-tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <span>Chart range: last 7 days</span>
                  </ReactTooltip>
                </Button>
                <Button
                  className="chartTimeButton"
                  onClick={() => {
                    this.chartTimeDuration("minute");
                  }}
                >
                  <span data-tip data-for="minuteChart">
                    minute
                  </span>
                  <ReactTooltip
                    id="minuteChart"
                    className="application-tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <span>Chart range: last 24h</span>
                  </ReactTooltip>
                </Button>
                <div className="trend" data-tip data-for="charttrend">
                  <span className="trend--headline">
                    Chart<b>trend</b>
                  </span>
                  <div className="trend--down">
                    <span className="fa fa-caret-down" />
                    {this.state.trendDown}
                  </div>
                  <div className="trend--up">
                    <span className="fa fa-caret-up" />
                    {this.state.trendUp}
                  </div>
                  <div className="trend-bar">
                    <div
                      className="trend-bar-pointer"
                      style={{ left: this.state.trendUp }}
                    />
                  </div>
                </div>
                <ReactTooltip
                  id="charttrend"
                  className="application-tooltip"
                  place="top"
                  type="dark"
                  effect="solid"
                >
                  <div className="application-tooltip--content">
                    <h3>
                      CHART<b>trend</b>
                    </h3>
                    <p>
                      This is an overview of the direction in which the chart
                      has moved since its last absolute chart high (red line)
                      and absolute chart low (green line), with the current
                      price.
                    </p>
                  </div>
                </ReactTooltip>
                <div className="pair-chart">
                <HighchartsReact  highcharts={Highcharts} constructorType={'stockChart'} options={chartConfig}></HighchartsReact>
                  </div>      
              </div>
            </div>
          </div>
          <div className="content-block">
            <h3>
              pair<b>data</b>
            </h3>
            <div className="row row-custom">
              <div className="col-sm col-sm-custom">
                <div className="desc">
                  current<b>Price</b> ({this.state.currencyASelected}/
                  {this.state.currencyBSelected}){" "}
                  <div className="desc-aditional">
                    <FontAwesomeIcon
                      icon={this.state.changePct24HourDirection}
                      size="2x"
                      color="#012423"
                    />

                    <span
                      className="direction-icon"
                      data-tip
                      data-for="changePct24HourDirection"
                    >
                      {this.state.changePct24Hour}%
                    </span>
                    <ReactTooltip
                      id="changePct24HourDirection"
                      className="application-tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      <span>Price change in the last 24 hours.</span>
                    </ReactTooltip>
                  </div>
                </div>

                <div className="number">
                  <span data-tip data-for="currentPrice">
                    {this.state.currentPrice} {this.state.currencyASelected}
                  </span>
                  <ReactTooltip
                    id="currentPrice"
                    className="application-tooltip"
                    place="top"
                    type="dark"
                    effect="solid"
                  >
                    <span>Current Price of selected currency pair.</span>
                  </ReactTooltip>
                </div>
              </div>

              <div className="col-sm col-sm-custom ">
                <div className="desc">
                  day<b>low</b> ({this.state.currencyASelected}/
                  {this.state.currencyBSelected})
                </div>
                <div className="number">
                  {this.state.priceLowDay} {this.state.currencyASelected}
                </div>
              </div>
              <div className="col-sm col-sm-custom ">
                <div className="desc">
                  day<b>hight</b> ({this.state.currencyASelected}/
                  {this.state.currencyBSelected})
                </div>
                <div className="number">
                  {this.state.priceHightDay} {this.state.currencyASelected}
                </div>
              </div>
            </div>
            <div className="row row-custom">
              <div className="col-sm col-sm-custom ">
                <div className="desc">
                  change<b>day</b>
                  <div className="desc-aditional">
                   <FontAwesomeIcon
                      icon={this.state.changePctDayDirection}
                      size="2x"
                      color="#012423"
                    />
                    <span
                      className="direction-icon"
                      data-tip
                      data-for="changePctDayDirection"
                    >
                      {this.state.changePctDay}%
                    </span>
                    <ReactTooltip
                      id="changePctDayDirection"
                      className="application-tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      <span>Price change for the day.</span>
                    </ReactTooltip>
                  </div>
                </div>
                <div className="number">
                  {this.state.changeDay} {this.state.currencyASelected}
                </div>
              </div>
              <div className="col-sm col-sm-custom ">
                <div className="desc">
                  change<b>24H</b>
                  <div className="desc-aditional">
                    <FontAwesomeIcon
                      icon={this.state.changePct24HourDirection}
                      size="2x"
                      color="#012423"
                    />

                    <span
                      className="direction-icon"
                      data-tip
                      data-for="changePct24HourDirection"
                    >
                      {this.state.changePct24Hour}%
                    </span>
                    <ReactTooltip
                      id="changePct24HourDirection"
                      className="application-tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                    >
                      <span>Price change in the last 24 hours.</span>
                    </ReactTooltip>
                  </div>
                </div>
                <div className="number">
                  {this.state.change24Hour} {this.state.currencyASelected}
                </div>
              </div>
            </div>
            <div className="row row-custom">
              <div className="col-sm col-sm-custom ">
                <div className="desc">
                  market<b>cap</b>
                </div>
                <div className="number">
                  {this.state.marketCap} {this.state.currencyASelected}
                </div>
              </div>
              <div className="col-sm col-sm-custom ">
                <div className="desc">
                  Total<b>Supply</b>
                </div>
                <div className="number">
                  {this.state.suppliy} {this.state.currencyBSelected}
                </div>
              </div>
            </div>
          </div>
          <CalcCurrencies
            currencyInvest={this.state.currencyInvest}
            currencyA={this.state.currencyA}
            currencyASelected={this.state.currencyASelected}
            onChangeValue={this.setCurrencyUpState}
          />
          <CalcExchange
            currencyInvest={this.state.currencyInvest}
            currencyA={this.state.currencyA}
            currencyASelected={this.state.currencyASelected}
            onChangeValue={this.setCurrencyUpState}
          />
        </div>
        <div className="last-of-page">
          <CryptoNews
            selected={this.state.currencyBSelected}
            ref={component => (this._child = component)}
          />
          <Social data={this.state.social} />
        </div>

        <div className="row-custom--footer">
          <div className="no-guarantee">All information without guarantee</div>
          <div className="col-sm col-sm-custom-million">
            <div className="desc">
              onemillion<b>price</b> {this.state.currencyASelected}/
              {this.state.currencyBSelected}
            </div>
            <div className="number">
              {this.state.oneMillionPrice} {this.state.currencyASelected}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MillionCalculation;
