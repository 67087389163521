import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import "./styles/styles.scss";
import * as serviceWorker from "./serviceWorker";
import MillionCalculation from "./components/millionCalculation";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faRedoAlt,
  faCaretDown,
  faCaretUp,
  faExchangeAlt,
  faPlusCircle

  //faExchange
} from "@fortawesome/free-solid-svg-icons";
import FooterNavigation from "./components/footerNavigation";

library.add(faRedoAlt, faCaretDown, faCaretUp, faExchangeAlt, faPlusCircle);

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
  <MillionCalculation />,
  document.getElementById("millionCalculation")
);

// ReactDOM.render(<CryptoNews />, document.getElementById("cryptoNews"));

ReactDOM.render(
  <FooterNavigation />,
  document.getElementById("footerNavigation")
);

// ReactDOM.render(<CurrencyUp />, document.getElementById("currencyUp"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
