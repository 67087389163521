import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

class Social extends Component {
  state = {
    data: ""
  };

  getValue(selector) {
    if (this.props.data !== undefined) {
      // console.log(this.props.data);
      return this.props.data[1000][selector];
    }
  }

  getChartData(selector) {
    if (this.props.data !== undefined) {
      var array = Array.from(Object.keys(this.props.data), k => [
        this.props.data[k].time * 1000,
        this.props.data[k][selector]
      ]);
      return array;
    }
  }

  render() {
    // console.log("SOCIAL", this.props.data);
    const xAxis = {
      type: "datetime",
      title: {
        text: "Date"
      }
    };

    const yAxis = {
      gridLineColor: "rgba(1, 36, 35, 0.25)",
      title: {
        text: "Price"
      }
    };

    const navigator = {
      handles: {
        backgroundColor: "#e8fcfb",
        borderColor: "#012423"
      },
      maskFill: "rgba(232, 251, 251, 0.75)",
      maskInside: true
    };
    const chartConfigDevelopment = {
      xAxis: xAxis,
      yAxis: yAxis,
      navigator: navigator,

      series: [
        {
          name: `Closed Issues`,
          data: this.getChartData("code_repo_closed_issues"),
          color: "#1eff00",
          marker: {
            symbol: "circle"
          },
          tooltip: {
            valueDecimals: 0
          }
        },
        {
          name: `Open Issues`,
          data: this.getChartData("code_repo_open_issues"),
          color: "#ff0000",
          marker: {
            symbol: "circle"
          },
          tooltip: {
            valueDecimals: 0
          }
        },
        {
          name: `Open Pull Requests`,
          data: this.getChartData("code_repo_open_pull_issues"),
          color: "#ffea00",
          marker: {
            symbol: "circle"
          },
          tooltip: {
            valueDecimals: 0
          }
        }
      ]
    };
    const chartConfigSocialMedia = {
      xAxis: xAxis,
      yAxis: yAxis,
      navigator: navigator,

      series: [
        {
          name: `Facebook Followers`,
          data: this.getChartData("followers"),
          color: "#3C5A99",
          marker: {
            symbol: "circle"
          },
          tooltip: {
            valueDecimals: 0
          }
        },
        {
          name: `Twitter Followers`,
          data: this.getChartData("twitter_followers"),
          color: "#1DA1F2",
          marker: {
            symbol: "circle"
          },
          tooltip: {
            valueDecimals: 0
          }
        },
        {
          name: `Reddit Subscribers`,
          data: this.getChartData("reddit_subscribers"),
          color: "#FF4500",
          marker: {
            symbol: "circle"
          },
          tooltip: {
            valueDecimals: 0
          }
        }
      ]
    };
    return (
      <div id="social" className="container container-custom">
        <div className="content-block">
          <div className="row row-custom">
            <h3>Development</h3>
            <div className="col-sm col-sm-custom col-sm-custom-chart">
              <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={chartConfigDevelopment}></HighchartsReact>
            </div>
          </div>
          <div className="row row-custom">
            <h3>SocialMedia</h3>
            <div className="col-sm col-sm-custom col-sm-custom-chart">
              <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={chartConfigSocialMedia}></HighchartsReact>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Social;
