// Generate a list of options
import React, { Component } from "react";
import PropTypes from "prop-types";
import "font-awesome/css/font-awesome.min.css";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

class DropdownCurrencys extends Component {
  state = {
    cSelected: "XRP",
    loopLog: 0
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.state = {
      dropdownOpen: false
    };

    this.items();
  }

  // Get Values from this Component and push to Parent Component (millionCalculation.jsx)
  static contextTypes = {
    parentMethod: PropTypes.func,
    handleAddTag: PropTypes.func
  };

  // Toggle Dropdown
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  clickHandler(selected) {
    this.setState({ cSelected: selected });
  }

  // Generate Dropdown items
  items() {
    const that = this;
    const json = this.props.items;
    const componentIdent = this.props.componentIdent;
    let dropdownItems = [];

    Object.keys(json).forEach(function(key, index, arr) {
      // console.log("ITEMS LOOP", key + "=" + json[key]);
      if (componentIdent === "calcExchange") {
        if (key !== "SELECTED") {
          dropdownItems.push(
            <DropdownItem
              key={index}
              onClick={() => {
                that.clickHandler(key);
                that.context.handleAddTag(json.id, key);
              }}
            >
              {json[key].FullName}
            </DropdownItem>
          );
        }
      } else {
        if (key !== "SELECTED") {
          dropdownItems.push(
            <DropdownItem
              key={index}
              onClick={() => {
                that.clickHandler(key);
                that.context.parentMethod(json.id, key);
              }}
            >
              {json[key].FullName}
            </DropdownItem>
          );
        }
      }

      if (!arr[index + 1]) {
        //console.log("END OF LOOP", dropdownItems);
      }
    });

    return dropdownItems;
  }

  // Render
  render() {
    /*console.log(
      "DropdownCurrencys props",
      this.state,
      this.props.items,
      this.state.cSelected
    );*/
    if (
      this.props.items.id === "currencyA" &&
      this.state.cSelected === undefined
    ) {
      this.defaultCurrencyA = "EUR";
    } else if (
      this.props.items.id === "currencyB" &&
      this.state.cSelected === undefined
    ) {
      this.defaultCurrencyA = "XRP";
    } else {
      this.defaultCurrencyA = "";
      this.defaultCurrencyB = "";
    }
    let faIcon = "";
    let cSelected = this.state.cSelected;
    let defaultCurrencyA = this.defaultCurrencyA;
    if (this.props.componentIdent === "calcExchange") {
      faIcon = <span className="fa fa-plus-circle dropdown-toggle--icon" />;
      cSelected = "add Currency";
      defaultCurrencyA = "";
    }

    return (
      <div>
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle caret>
            {faIcon}
            {defaultCurrencyA}
            {cSelected}
          </DropdownToggle>
          <DropdownMenu
            modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: data => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: "auto",
                      maxHeight: 400
                    }
                  };
                }
              }
            }}
          >
            {this.items()}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export default DropdownCurrencys;
