import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { TwitterDMButton } from "react-twitter-embed";
import styles from "../modal.css";

class FooterNavigation extends Component {
  state = {
    open: false
  };

  constructor(props) {
    super(props);

    this.modalListener = this.modalHandler.bind(this);
  }

  modalHandler(state, name) {
    if (state === "open") {
      this.setState({ [name]: true });
    }

    if (state === "close") {
      this.setState({ [name]: false });
    }
  }

  render() {
    return (
      <div>
        <button onClick={() => this.modalListener("open", "help")}>help</button>
        <Modal
          open={this.state.help || this.state.open}
          onClose={() => this.modalListener("close", "help")}
          center
          className={{
            overlay: styles.customOverlay,
            modal: styles.customModal
          }}
        >
          <div className="help">
            <h2>What can I do here?</h2>
            <h3>
              CALC<b>AVERAGE</b>
            </h3>
            <p>
              Calculate how much you will get for your investment at the current
              price. You already have cryptocurrencies in your wallet? For how
              much (for example: USD or EUR) did you buy them instantly and how
              much does that have in your wallet? This page calculates the
              average price of your wallet and tells you from which price you
              will make a profit. The result is also displayed as red or green
              line in PAIR
              <b>CHART</b>.
            </p>
            <h3>
              PAIR<b>CHART</b>
            </h3>
            <p>
              The chart shows the current currency price movement. It also
              includes the, CURRENT<b>PRICE</b>, CALC
              <b>AVERAGE</b> and CHART<b>TREND</b>.
            </p>
            <h3>
              PAIR<b>DATA</b>
            </h3>
            <p>Get informations from selected currency pair.</p>
            <h3>
              CALC<b>CURRENCIES</b>
            </h3>
            <p>
              Add currencies to show the current currency exchange rate of the
              respective currency to its destination. The calculation of the
              goal is based on the CURRENCY<b>INVEST</b>.
            </p>
            <h3>
              CALC<b>EXCHANGE</b>
            </h3>
            <p>
              The CURRENCY<b>INVEST</b> is the beginning of this calculation.
              Add currencies, remove them and sort them in a new order. The
              final result is always the chosen CURRENCY<b>INVEST</b> currency.
              With different combinations you end up with more or less of the
              investment. All values are without guarantee. The calculation is
              based on the CURRENT<b>PRICE</b> of the currencies. Change costs
              are not considered.
            </p>
            <h3>
              CRYPTO<b>NEWS</b>
            </h3>
            <p>
              Get the latest news about the selected CURRENCY
              <b>TARGET</b>.
            </p>
            <h3>
              ONEMILLION<b>PRICE</b>
            </h3>
            <p>
              At what price would you be a millionaire with your defined
              CURRENCY
              <b>INVEST</b> and self selected CURRENCY
              <b>TARGET</b>.
            </p>
          </div>
        </Modal>
        <button onClick={() => this.modalListener("open", "imprint")}>
          Legal Notice
        </button>
        <Modal
          open={this.state.imprint || this.state.open}
          onClose={() => this.modalListener("close", "imprint")}
          modalId="test"
          overlayId="test"
          center
          className={{
            overlay: styles.customOverlay,
            modal: styles.customModal
          }}
        >
          <h2>Limitation of liability for internal content</h2>

          <p>
            The content of our website has been compiled with meticulous care
            and to the best of our knowledge. However, we cannot assume any
            liability for the up-to-dateness, completeness or accuracy of any of
            the pages.
          </p>
          <p>
            Pursuant to section 7, para. 1 of the TMG (Telemediengesetz – Tele
            Media Act by German law), we as service providers are liable for our
            own content on these pages in accordance with general laws. However,
            pursuant to sections 8 to 10 of the TMG, we as service providers are
            not under obligation to monitor external information provided or
            stored on our website. Once we have become aware of a specific
            infringement of the law, we will immediately remove the content in
            question. Any liability concerning this matter can only be assumed
            from the point in time at which the infringement becomes known to
            us.
          </p>
          <p>
            <strong>Limitation of liability for external links</strong>
          </p>
          <p>
            Our website contains links to the websites of third parties
            („external links“). As the content of these websites is not under
            our control, we cannot assume any liability for such external
            content. In all cases, the provider of information of the linked
            websites is liable for the content and accuracy of the information
            provided. At the point in time when the links were placed, no
            infringements of the law were recognisable to us. As soon as an
            infringement of the law becomes known to us, we will immediately
            remove the link in question.
          </p>
          <p>
            <strong>Copyright</strong>
          </p>
          <p>
            The content and works published on this website are governed by the
            copyright laws of Germany. Any duplication, processing, distribution
            or any form of utilisation beyond the scope of copyright law shall
            require the prior written consent of the author or authors in
            question.
          </p>
          <p>
            <strong>Data protection</strong>
          </p>
          <p>
            A visit to our website can result in the storage on our server of
            information about the access (date, time, page accessed). This does
            not represent any analysis of personal data (e.g., name, address or
            e-mail address). If personal data are collected, this only occurs –
            to the extent possible – with the prior consent of the user of the
            website. Any forwarding of the data to third parties without the
            express consent of the user shall not take place.
          </p>
          <p>
            We would like to expressly point out that the transmission of data
            via the Internet (e.g., by e-mail) can offer security
            vulnerabilities. It is therefore impossible to safeguard the data
            completely against access by third parties. We cannot assume any
            liability for damages arising as a result of such security
            vulnerabilities.
          </p>
          <p>
            The use by third parties of all published contact details for the
            purpose of advertising is expressly excluded. We reserve the right
            to take legal steps in the case of the unsolicited sending of
            advertising information; e.g., by means of spam mail.
          </p>
          <p>
            Source:{" "}
            <a href="http://www.mustervorlage.net/disclaimer-muster#Englisch">
              Disclaimer on Mustervorlage.net
            </a>
          </p>
        </Modal>

        <div className="twitter-float">
          <TwitterDMButton id={453867892} options={{ text: "CRYPTO-CALC: " }} />{" "}
        </div>
      </div>
    );
  }
}

export default FooterNavigation;
